<template>
  <div class="dashboard-wrap">
    <div class="dashboard">
      <div class="top-bar flex justify-between">
        <div class="flex items-center">
          <span class="font-bold">套餐订单</span>
        </div>
        <a-button @click="$router.go(-1)" type="primary">返回工作台</a-button>
      </div>

      <a-form-model
        layout="inline"
        :form="searchValue"
        style="margin-bottom: 20px; margin-left: 5px"
      >
        <a-form-model-item label="时间" prop="date">
          <a-range-picker
            v-model="searchValue.date"
            style="width: 337px"
            format="YYYY-MM-DD"
          />
        </a-form-model-item>

        <a-form-model-item label="订单类型" prop="type">
          <a-select
            v-model="searchValue.type"
            style="width: 160px"
            :options="typeDic"
          ></a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" @click="getList">搜索</a-button>
        </a-form-model-item>
      </a-form-model>

      <a-table
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #statusInfo="statusInfo">
          <a-button
            v-if="statusInfo.status / 1 === 1 && statusInfo.type / 1 === 2"
            @click="handleEdit({ id: statusInfo.id, status: 3 })"
          >
            自提
          </a-button>
          <a-button
            v-else-if="statusInfo.status / 1 === 1 && statusInfo.type / 1 === 4"
            @click="handleEdit({ id: statusInfo.id, status: 4 })"
          >
            闪送
          </a-button>
          <span v-else>{{ readDicFn(statusInfo.status / 1, statusDic) }}</span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { packageOrderList, packageConfirmOrder } from '@/api/Official'
import moment from 'moment'
import { checkEmpty, readDicFn } from '@/utils/utils'

export default {
  name: 'BookReview',
  data() {
    return {
      columns: [
        {
          title: '订单id',
          dataIndex: 'id',
        },
        {
          title: '团购套餐名称',
          dataIndex: 'to_package.name',
        },
        {
          title: '订单类型',
          dataIndex: 'type',
        },
        {
          title: '下单时间',
          dataIndex: 'created_at',
        },
        {
          title: '电话',
          dataIndex: 'phone',
        },
        {
          title: '金额(元)',
          dataIndex: 'order_money',
        },
        {
          title: '订单状态',
          dataIndex: 'statusInfo',
          scopedSlots: { customRender: 'statusInfo' },
        },
      ],

      searchValue: {
        date: [],
        type: '',
      },
      list: [],
      pagination: {
        current: 1,
        pageSize: 6,
        total: 0,
        showTotal: (total) => `共${total}条`,
      },

      typeDic: [
        { label: '全部', value: '' },
        { label: '到店就餐', value: 1 },
        { label: '到店自提', value: 2 },
        { label: '闪送到家', value: 4 },
      ],

      statusDic: [
        { label: '待支付', value: 0 },
        { label: '待使用', value: 1 },
        { label: '已完成', value: 2 },
        { label: '已自提', value: 3 },
        { label: '已闪送', value: 4 },
      ],
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    ...mapState('user', { infoChange: 'infoChange' }),
  },
  methods: {
    ...mapActions('user', ['setInfoChange']),

    readDicFn,

    // 获取消息列表
    getList(page = 1) {
      this.$store.dispatch('loading/startContentLoading')

      const params = {
        page,
        start_time: this.searchValue.date[0]
          ? `${moment(this.searchValue.date[0]).format('YYYY-MM-DD')} 00:00:00`
          : '',
        end_time: this.searchValue.date[0]
          ? `${moment(this.searchValue.date[0]).format('YYYY-MM-DD')} 23:59:59`
          : '',
        type: this.searchValue.type,
      }
      packageOrderList(params)
        .then((res) => {
          this.setInfoChange(this.infoChange + 1)

          // 定义分页信息
          this.pagination.pageSize = res.data.per_page || 6
          this.pagination.total = res.data.total || 0

          this.list = (res.data.data || []).map((item) => {
            item.order_money = ((item.order_money || 0) / 100).toFixed(2)
            item.statusInfo = {
              status: item.status,
              type: item.type,
              id: item.id,
            }

            if (!checkEmpty(item.type)) {
              item.type = readDicFn(item.type / 1, this.typeDic)
            }

            return item
          })
        })
        .finally(() => {
          this.$store.dispatch('loading/endContentLoading')
        })
    },

    // 分页
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getList(pagination.current)
    },

    handleEdit(params) {
      let title = ''
      let content = ''
      switch (params.status) {
        case 3:
          title = '自提'
          content = '是否确定该顾客已到店自提？'
          break
        case 4:
          title = '闪送'
          content = '是否确定已为该顾客送货？'
          break
      }

      this.$confirm({
        title,
        content,
        onOk: () => {
          packageConfirmOrder(params).then((res) => {
            if (res.code === 0) {
              this.$message.success('操作成功！')
              this.getList()
            }
          })
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
.dashboard-wrap {
  background-color: #f0f2f5;
  padding: 24px;
  box-sizing: border-box;
}

.dashboard {
  width: 100%;
  min-height: calc(100vh - 114px);
  background-color: #fff;
  border-radius: 4px;
  padding: 25px 22px;
}

.top-bar {
  margin-bottom: 30px;
}

.imgItem {
  height: 40px;
  width: 40px;
}

.information {
  .information_title {
    font: 500 16px/18px 'PingFangSC-Medium';
    color: #333;
  }
  .information_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    font: 400 14px/14px 'PingFangSC-Regular';
    color: #666;
  }
}
</style>
